<template>
    <div class="rainbow-chart-legend-container" style="margin-bottom: 2vh">
        <div v-if="showBlueKey" class="rainbow-chart-legend" :style="{ background: RainbowColours.blue }"> </div>{{ showBlueKey ? blueKeyText : '' }}
        <div class="rainbow-chart-legend" :style="{ background: RainbowColours.red }"> </div> {{ keyText }} 0%-15%
        <div class="rainbow-chart-legend" :style="{ background: RainbowColours.orange }"> </div> {{ keyText }}  15%-35%
        <div class="rainbow-chart-legend" :style="{ background: RainbowColours.yellow }"> </div> {{ keyText }}  35%-65%
        <div class="rainbow-chart-legend" :style="{ background: RainbowColours.lime }"> </div> {{ keyText }}  65%-85%
        <div class="rainbow-chart-legend" :style="{ background: RainbowColours.green }"> </div> {{ keyText }}  85%-100%
    </div>
</template>

<script>
import { RainbowColours } from "./RainbowColours";
export default {
    props: ['showBlueKey', 'blueKeyText', 'keyText'],
    data() {
        return {
            RainbowColours: RainbowColours
        };
    }
};
</script>

<style scoped>
.rainbow-chart-legend-container {
    display: flex;
    justify-content: space-evenly;
    margin: auto;
    width: 60%;
    font-size: 12px;
    align-items: center;
}
</style>