<template>
    <div class="row w-100 mx-auto d-flex flex-column flex-lg-row align-items-center" v-if="chartData">
        <h1 class="col-4 col-lg-2 mx-auto teamBwType align-middle text-right"> {{ title }}
        </h1>
        <div class="row w-100 mx-auto col-12 col-lg-10">
            <div class="col-3 col-lg-2">
                <BoxWhiskerLabel class="" :id="id + '-chart-label'" :data="labelData" :options="chartOptions"
                    :width="20" :bwHeight="50" />
            </div>
            <div class="col-8">
                <BoxWhiskerChart class="teamBwChart" :id="id + '-chart'" :data="chartData" :options="chartOptions"
                    :width="60" :bwHeight="50" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: String,
        title: String,
        chartOptions: Object,
        chartData: Array,
        labelData: Array,
    },
};
</script>

<style>
.teamBwType {
    font-size: 14px;
}
</style>