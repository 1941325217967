<template>
    <div class="row w-75 mx-auto" v-if="turnRates">
        <div class="col-4 d-flex justify-content-center align-items-center legends">
            <div class="legend" :style="{ 'background-color': legendColours.low }"> </div> <span>Low<br> {{turnRates.low
                }}</span>
        </div>
        <div class="col-4 d-flex justify-content-center align-items-center legends">
            <div class="legend" :style="{ 'background-color': legendColours.medium }"></div> <span>Medium <br> {{
                turnRates.medium }} </span>
        </div>
        <div class="col-4 d-flex justify-content-center align-items-center legends">
            <div class="legend" :style="{ 'background-color': legendColours.high }"></div> <span>High <br> {{
                turnRates.high }} </span><br>
        </div>

    </div>
</template>

<script>
import { MeasurementTypes, getMeasurementUnit } from "../utils/MeasurementSystem";
import Colours from "../utils/Colours";

export default {
    props: {
        type: String,
    },
    data() {
        return {
            accelerationUnit: getMeasurementUnit(MeasurementTypes.Acceleration),
            speedUnit: getMeasurementUnit(MeasurementTypes.Speed),
            turnRates: null,
            legendColours: null
        };
    },
    methods: {
        getLegendData() {
            const getAccelerationConvertedValues = v => this.accelerationUnit.formatStandard(v) + this.accelerationUnit.unitShort;
            const getSpeedConvertedValues = v => this.speedUnit.formatStandard(v) + this.speedUnit.unitShort;
            const accelerationsLabels = {
                low: `< ${getAccelerationConvertedValues(4)}`,
                medium: `> ${getAccelerationConvertedValues(4)} & < ${getAccelerationConvertedValues(5)}`,
                high: `> ${getAccelerationConvertedValues(5)}`
            }; 

            const entrySpeedLabels = {
                low: `< ${getSpeedConvertedValues(3)}`,
                medium: `> ${getSpeedConvertedValues(3)} & < ${getSpeedConvertedValues(5.5)}`,
                high: `> ${getSpeedConvertedValues(5.5)}`
            };

            const angle = { low: '< 60°', medium: '> 60° & < 120°', high: '> 120°' };
            const effort = { low: 'Low Angle & Low Deceleration', medium: '', high: 'High Angle & High Deceleration' };
            const turnRates = { angle, deceleration: accelerationsLabels, acceleration: accelerationsLabels, effort, speed: entrySpeedLabels };
            const { byAngleClass, byDecelerationPeakClass, byAccelerationPeakClass, byEffortClass, byEntrySpeed } = Colours.TURN_RATES;
            const legendColours = {
                angle: { ...byAngleClass },
                deceleration: { ...byDecelerationPeakClass },
                acceleration: { ...byAccelerationPeakClass },
                effort: { ...byEffortClass },
                speed: { ...byEntrySpeed },
            };
            this.turnRates = turnRates[this.type];
            this.legendColours = legendColours[this.type];
        }
    },
    async mounted() {
        this.getLegendData();
    }
};
</script>

<style scoped>
.legends{
    font-size: 0.8em;
    white-space: nowrap;
}
.legend{
    padding: 1em;
    margin: 1em 0.9em;
    text-align: center;
}

</style>