<template>
    <div class="width:100%">
        <span style="display:none">{{ refresh }}</span>
        <canvas :id="canvasId"  />
    </div>
</template>

<script>

export default {
    data() {
        return {
            chart: null,
        };
    },
    props: {
        canvasId: null,
        chartOptions: Object,
        data: Object,
        chartFunction: Function,
        refresh: null // used to force a refresh of this component.
    },
    methods: {
        createCharts() {
            if (this.data) {
                
                if (this.chart) {
                    this.chart.destroy();
                }
                
                const elementById = document.getElementById(this.canvasId);
                
                if (elementById) {
                
                    const ctx = elementById.getContext('2d');
    
                    this.chart = this.chartFunction(
                        ctx,
                        this.data,
                        this.chartOptions
                    );
                }
            }
        }
    },
    mounted() {
        console.log("TurnReportColumn.vue mounted");
        this.createCharts();
    },
    updated() {
        console.log("TurnReportColumn: called updated");
        this.createCharts();
    },
};
</script>