<template>
    <div>
        <SectionTitle :title="'Sprint Counts Summary'">
            <MoreInfo :id="'sprint-count-info-modal'" :title="'Sprint Counts Summary'">
                <ul>
                    <li>Sprint Counts Summary provides a visual comparison between the team’s & players’ current session
                        and their previous ones.
                        The coloured line represents the current session, while the box and whisker plot represents the
                        distribution of previous matches.</li>

                    <li>HSR captures distance covered
                        between
                        <MeasurementValue :type="Speed" :value=5.5 :format="formatStandard" />
                        <MeasurementUnit :type="Speed" /> and
                        <MeasurementValue :type="Speed" :value=7 :format="formatStandard" />
                        <MeasurementUnit :type="Speed" />
                    </li>
                    <li>Sprint captures distance covered above
                        <MeasurementValue :type="Speed" :value=7 :format="formatStandard" />
                        <MeasurementUnit :type="Speed" />
                    </li>

                    <li>Total: Current session compared with all previous {{playersSessionReferenceGroup}} matches with
                        at least 85 minutes of match time {{getTotalText}}</li>
                    <BoxWhiskerAdditionalInfoText currentDataText="current session"
                        referenceDataText="team's & players'" />
                    <li>(*) - played &lt; 90mins</li>
                </ul>
            </MoreInfo>
        </SectionTitle>
        <MissingDataText v-if="!kpiCount || !teamKpiCount" :message="'Sprint Counts Unavailable'" />
        <div v-else>
            <MatchTimeIndicator />
            <div class="mx-auto" style="width: 50vw;">
                <div class="row d-flex w-50 mx-auto">
                    <RadioButton class="col-12 col-lg-6" :id="'speed-type-radio-group'" :modal="speedTypeSelected"
                        :options="speedTypeOptions" :name="'speed-type-options'"
                        @update-modal="speedTypeSelected = $event" :task="toggleSpeedType" />

                    <div class="col-12 col-lg-6">
                        <span>Sort by: </span>
                        <b-dropdown variant="outline-primary" :text="sortGroupOptions[this.sortGroupId].text">
                            <b-dropdown-item v-for="(options, index) in sortGroupOptions" v-bind:key="index"
                                @click="toggleSortGroup(index)" class="pt-2">
                                {{ options.text }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>
            <div>
                <div class="row p-0 w-100 g-0">
                    <div class="row col-lg-2 g-0" v-if="!isSmallerScreen">
                        <div class="col-12" v-if="teamKpiCount && sessionIsAMatch">
                            <BoxWhiskerLabel :id="'team-bw-label'" :data="teamKpiCount.max10Counts.labels"
                                :options="teamLabelOptions" :width="labelWidth" :bwHeight="90" />
                        </div>
                        <div class="col-12">
                            <BoxWhiskerLabel :id="'player-bw-label'" :data="kpiCount.max10Counts.labels"
                                :options="playersCountOptions" :width="labelWidth" :bwHeight="bwHeight"
                                v-if="kpiCount" />
                        </div>
                    </div>
                    <div v-for="layout in kpiLayouts" :key="layout.id" class="col-lg-2">
                        <KpiCountBWChart class="mt-4 mt-lg-0" :id="'team-' + layout.id"
                            :labelData="teamKpiCount[layout.kpi].labels" :labelOptions="teamLabelOptions"
                            :chartOptions="layout.teamChartOptions" :plotData="teamKpiCount[layout.kpi].kpi"
                            :bwWidth="plotWidth" :displayPlayerLabels="isSmallerScreen" :bwHeight="90" v-if="teamKpiCount && sessionIsAMatch" />

                        <KpiCountBWChart :id="layout.id" :labelData="kpiCount[layout.kpi].labels"
                            :labelOptions="playersCountOptions" :chartOptions="layout.playerChartOptions"
                            :plotData="kpiCount[layout.kpi].kpi" :matchClocks="matchClocks[layout.matchClocks]"
                            :matchClocksFormatter="MatchClocksFormatter" :bwWidth="plotWidth" :bwHeight="bwHeight"
                            :displayPlayerLabels="isSmallerScreen" v-if="kpiCount" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { errorHandler } from "@/components/ErrorHandler";
import { UserData } from "@/components/UserData";
import { getMeasurementUnit, MeasurementTypes, MeasurementUnit } from '@/utils/MeasurementSystem';
import { getPlayerKpiBwData } from "@/utils/BoxWhiskerKpiInfoGenerator";
import store from "@/store/session";
import MatchClocksFormatter from "@/utils/MatchClocksFormatter";
import SprintThresholdCountOptions from "@/utils/SprintThresholdCountOptions.js";
import KpiCountBWChart from './KpiCountBWChart';
import BREAKPOINT_LG from "@/utils/Breakpoints";
const SPEED_TYPE = { SPRINT: 0, HSR: 1 };

export default {
    components: {
        KpiCountBWChart
    },
    computed: {
        sessionIsAMatch() {
            return store.state.sessionIsAMatch;
        },
        playersSessionReferenceGroup() {
            return store.state.playersSessionReferenceGroup;
        },
        teamSessionReferenceGroup() {
            return store.state.teamSessionReferenceGroup;
        },
        getTotalText: function () {
            if (store.state.sessionIsAcademyMatch) {
                return `for the players and all previous ${this.teamSessionReferenceGroup} matches with at least 85 minutes of match time for the team`;
            }
            return null;
        },
        defaultOptions() {
            return {
                tooltipLabel: this.sessionIsAMatch ? "Current Match" : "Current Session",
                showTicks: false,
                showTitle: true,
                precision: 0,
                labelPrecision: 0,
                averagePrecision: 1,
            };
        },
        titles() {
            const title = this.speedTypeSelected === SPEED_TYPE.HSR ? 'HSR' : 'Sprint';
            return {
                total: title + ' Total',
                _0_to_10: title + ' 0-' + this.measurementUnit.formatCoarseUnit(10),
                _10_to_20: title + ' ' + this.measurementUnit.formatCoarse(10) + '-' + this.measurementUnit.formatCoarseUnit(20),
                _20_to_30: title + ' ' + this.measurementUnit.formatCoarse(20) + '-' + this.measurementUnit.formatCoarseUnit(30),
                _30_plus: title + ' ' + this.measurementUnit.formatCoarseUnit(30) + '+',
            };
        },
        playersCountOptions() {
            return {
                ...this.defaultOptions,
                showTitle: !this.sessionIsAMatch,
                showTicks: true,
                title: this.titles._0_to_10
            };
        },
        teamTotalCountOptions() {
            return {
                ...this.defaultOptions,
                title: this.titles.total,
            };
        },
        teamLabelOptions() {
            return {
                ...this.defaultOptions,
                title: '',
            };
        },
        teamMax10CountsOptions() {
            return {
                ...this.defaultOptions,
                title: this.titles._0_to_10,
            };
        },
        teamMax20CountsOptions() {
            return {
                ...this.defaultOptions,
                title: this.titles._10_to_20,
            };
        },
        teamMax30CountsOptions() {
            return {
                ...this.defaultOptions,
                title: this.titles._20_to_30,
            };
        },
        teamAbove30CountsOptions() {
            return {
                ...this.defaultOptions,
                title: this.titles._30_plus,
            };
        },
        playerTotalCountOptions() {
            return {
                ...this.playersCountOptions,
                title: this.titles.total,
            };
        },
        playerMax10CountsOptions() {
            return {
                ...this.playersCountOptions,
                title: this.titles._0_to_10,
            };
        },
        playerMax20CountsOptions() {
            return {
                ...this.playersCountOptions,
                title: this.titles._10_to_20,
            };
        },
        playerMax30CountsOptions() {
            return {
                ...this.playersCountOptions,
                title: this.titles._20_to_30,
            };
        },
        playerAbove30CountsOptions() {
            return {
                ...this.playersCountOptions,
                title: this.titles._30_plus,
            };
        },
        sortGroupOptions() {
            return SprintThresholdCountOptions;
        },
        bwHeight() {
            return this.kpiCount.totalCount.kpi.length <= 2 ? 110 : 55;
        },
        kpiLayouts() {
            return [
                {
                    id: 'total',
                    kpi: 'totalCount',
                    teamChartOptions: this.teamTotalCountOptions,
                    playerChartOptions: this.playerTotalCountOptions,
                    matchClocks: null,
                },
                {
                    id: '10',
                    kpi: 'max10Counts',
                    teamChartOptions: this.teamMax10CountsOptions,
                    playerChartOptions: this.playerMax10CountsOptions,
                    matchClocks: 'max10MatchClocks',
                },
                {
                    id: '20',
                    kpi: 'max20Counts',
                    teamChartOptions: this.teamMax20CountsOptions,
                    playerChartOptions: this.playerMax20CountsOptions,
                    matchClocks: 'max20MatchClocks',
                },
                {
                    id: '30',
                    kpi: 'max30Counts',
                    teamChartOptions: this.teamMax30CountsOptions,
                    playerChartOptions: this.playerMax30CountsOptions,
                    matchClocks: 'max30MatchClocks',
                },
                {
                    id: '30-plus',
                    kpi: 'above30Counts',
                    teamChartOptions: this.teamAbove30CountsOptions,
                    playerChartOptions: this.playerAbove30CountsOptions,
                    matchClocks: 'above30MatchClocks',
                }
            ];
        }
    },

    data() {
        return {
            Speed: MeasurementTypes.Speed,
            customerId: UserData.customerId(),
            isWIP: UserData.isWIP(),
            plotWidth: window.innerWidth <= BREAKPOINT_LG ? 65 : 15,
            isSmallerScreen: window.innerWidth <= BREAKPOINT_LG,
            labelWidth: 180,
            speedTypeSelected: 0,
            speedTypeOptions: [
                { text: 'Sprint', value: SPEED_TYPE.SPRINT },
                { text: 'HSR', value: SPEED_TYPE.HSR }
            ],
            sprintCount: null,
            hsdCount: null,
            kpiCount: null,
            teamKpiCount: null,
            playersData: null,
            teamData: null,
            sortGroupId: 0,
            sortGroupSelected: 'TOTAL',
            formatStandard: MeasurementUnit.prototype.formatStandard,
            measurementUnit: getMeasurementUnit(MeasurementTypes.ShortDistance),
            matchClocks: null
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => { });
        },
        async refreshData() {
            await this.$root.executeTaskWithProgressBar(async () => {
                this.sessionId = this.$route.params.id;
                await this.getData();
                await this.getPlayerChartData();
                await this.getTeamChartData();
            });
        },
        async getData() {
            const response = await this.$root.webApiGet(
                `/kpicount?customerId=${this.customerId}&sessionId=${this.sessionId}`
            );
            if (response.status === 200) {
                this.teamData = response.data.teamKpiCount;
                this.playersData = response.data.playersKpiCount;
            } else {
                errorHandler.error(response, this);
            }
        },
        async getTeamChartData() {
            if (this.teamData) {
                const kpiCount = this.speedTypeSelected === SPEED_TYPE.HSR ? this.teamData.kpiCounts.HSR : this.teamData.kpiCounts.SPRINT;
                const getKpiInfo = k => {
                    const { value, average, standardDeviation } = kpiCount[k];
                    kpiCount[k].belowBounds = value < (average - standardDeviation);
                    kpiCount[k].aboveBounds = value > (average + standardDeviation);
                    const kpi = [kpiCount[k]];
                    const labels = ['Team Total'];
                    return { kpi, labels };
                };
                this.teamKpiCount = {
                    totalCount: getKpiInfo('TOTAL'), max10Counts: getKpiInfo('_0_TO_10'), max20Counts: getKpiInfo('_10_TO_20'),
                    max30Counts: getKpiInfo('_20_TO_30'), above30Counts: getKpiInfo('ABOVE_30')
                };
            }
        },
        async getPlayerChartData() {
            const playersKpiCount = this.playersData.map(t => ({ ...(this.speedTypeSelected === SPEED_TYPE.HSR ? t.kpiCounts.HSR : t.kpiCounts.SPRINT), ...t }));

            if (playersKpiCount.length > 0) {
                const sortedKpis = playersKpiCount.sort((a, b) => b[this.sortGroupSelected].value - a[this.sortGroupSelected].value);
                
                const getKpiInfo = k => getPlayerKpiBwData(sortedKpis, k, d => d);
                

                this.kpiCount = { totalCount: getKpiInfo('TOTAL'), max10Counts: getKpiInfo('_0_TO_10'), max20Counts: 
                        getKpiInfo('_10_TO_20'), max30Counts: getKpiInfo('_20_TO_30'), above30Counts: getKpiInfo('ABOVE_30') };

                const getMatchClocks = c => {
                    return sortedKpis.map(p => p.sprintMatchClocks[c]);
                };

                if (this.speedTypeSelected === SPEED_TYPE.SPRINT) {
                    this.matchClocks = { max10MatchClocks: getMatchClocks("_0_TO_10"), max20MatchClocks: getMatchClocks("_10_TO_20"),
                        max30MatchClocks: getMatchClocks("_20_TO_30"), above30MatchClocks: getMatchClocks("ABOVE_30") };
                } else {
                    this.matchClocks = { max10MatchClocks: null, max20MatchClocks: null,
                        max30MatchClocks: null, above30MatchClocks: null };
                }
            }
        },
        toggleSpeedType() {
            this.getTeamChartData();
            this.getPlayerChartData();
            this.toggleSortGroup(0); //reset sort by dropdown 
        },
        toggleSortGroup(p) {
            this.sortGroupId = p;
            this.sortGroupSelected = this.sortGroupOptions[p].value;
            this.getPlayerChartData();
        },
        MatchClocksFormatter,
        updateViewport() {
            this.plotWidth = window.innerWidth <= BREAKPOINT_LG ? 65 : 15;
            this.isSmallerScreen = window.innerWidth <= BREAKPOINT_LG;
        }
    },
    async mounted() {
        console.log("SessionKpiCount.vue mounted");
        window.addEventListener("resize", this.updateViewport);
        await this.refreshData();
    },
};
</script>
<style scoped>
li {
    margin-bottom: 10px;
}
</style>