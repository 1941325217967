import { format } from 'date-fns';
class DataEntryBuilder {
    constructor(data, dataType, matchType) {
        this.data = data;
        this.dataType = dataType;
        this.matchType = matchType;
        this.currentItem = null;
    }

    setItem(item) {
        this.currentItem = item;
    }

    createValueAndPercentileObject(value, percentile) {
        const formattedValue = value === null ? "-" : value;
        const formattedPercentile = percentile === null ? "-" : Math.round(percentile);
        return {
            value: this.dataType === "data" ? formattedValue : formattedPercentile,
            percentile: formattedPercentile
        };
    }

    retrieveKpi(kpiType, threshold) {
        // WR is time based and other kpis are distance based
        const thresholdAndPercentileKeys = kpiType === 'WORK_RATE' ? ['highResolutionTimeByThreshold', 'highResolutionTimePercentileByThreshold'] :
            ['highResolutionDistanceByThreshold', 'highResolutionDistancePercentileByThreshold'];
        // Retrieve the KPI value/percentile if it exists or set it to null if undefined or missing
        // Use nullish coalescing (??) to ensure 0 is treated as a valid value and not replaced with null
        const rawValue = this.currentItem.reportingKpis[thresholdAndPercentileKeys[0]]?.[this.matchType]?.[kpiType]?.[threshold] ?? null;
        const value = (kpiType === "WORK_RATE" && rawValue != null) ? `${Math.floor(rawValue / 60)}:${format(new Date((rawValue % 60) * 1000), "ss")}` : rawValue;
        const percentile = this.currentItem.reportingKpis[thresholdAndPercentileKeys[1]]?.[this.matchType]?.[kpiType]?.[threshold] ?? null;
        return this.createValueAndPercentileObject(value, percentile);
    }

    buildDataEntry() {
        const {
            player,
            sessionInfo,
            reportingKpis
        } = this.currentItem;

        const {
            sportlightMatchTimeM,
            wellnessKpis,
            totalDistanceM,
            totalDistanceMPercentile,
            scaledWorkRate,
            scaledWorkRatePercentile,
        } = reportingKpis;
        
        const tableItem = {
            matchTime: sportlightMatchTimeM,
            fatigueFlag: wellnessKpis.fatigueFlagValue,
            hamstringRisk: {
                hamstringRisk: wellnessKpis.hamstringRisk,
                hamstringRiskScore: wellnessKpis.hamstringRiskScore
            },
            totalDistance: this.createValueAndPercentileObject(totalDistanceM, totalDistanceMPercentile),
            workRate: this.createValueAndPercentileObject(scaledWorkRate, scaledWorkRatePercentile),
            timeOver85WR: this.retrieveKpi("WORK_RATE", "85.00"),
            timeOver95WR: this.retrieveKpi("WORK_RATE", "95.00"),
            timeOver105WR: this.retrieveKpi("WORK_RATE", "105.00"),
            timeOver115WR: this.retrieveKpi("WORK_RATE", "115.00"),
            timeOver120WR: this.retrieveKpi("WORK_RATE", "120.00"),
            distanceOver17kmph: this.retrieveKpi("SPEED", "17.00"),
            distanceOver20kmph: this.retrieveKpi("SPEED", "20.00"),
            distanceOver23kmph: this.retrieveKpi("SPEED", "23.00"),
            distanceOver25_5kmph: this.retrieveKpi("SPEED", "25.50"),
            distanceOver27_5kmph: this.retrieveKpi("SPEED", "27.50"),
            distanceOver29kmph: this.retrieveKpi("SPEED", "29.00"),
            distanceOver30_5kmph: this.retrieveKpi("SPEED", "30.50"),
            distanceOver31_5kmph: this.retrieveKpi("SPEED", "31.50"),
            accelerationOver2mps2: this.retrieveKpi("ACCELERATION", "2.00"),
            accelerationOver2_5mps2: this.retrieveKpi("ACCELERATION", "2.50"),
            accelerationOver3mps2: this.retrieveKpi("ACCELERATION", "3.00"),
            accelerationOver3_5mps2: this.retrieveKpi("ACCELERATION", "3.50"),
            accelerationOver4mps2: this.retrieveKpi("ACCELERATION", "4.00"),
            accelerationOver6mps2: this.retrieveKpi("ACCELERATION", "6.00"),
            decelerationUnder2mps2: this.retrieveKpi("DECELERATION", "-2.00"),
            decelerationUnder2_5mps2: this.retrieveKpi("DECELERATION", "-2.50"),
            decelerationUnder3_25mps2: this.retrieveKpi("DECELERATION", "-3.25"),
            decelerationUnder4mps2: this.retrieveKpi("DECELERATION", "-4.00"),
            decelerationUnder4_75mps2: this.retrieveKpi("DECELERATION", "-4.75"),
            decelerationUnder5_5mps2: this.retrieveKpi("DECELERATION", "-5.50"),
        };
        
        if (player) {
            tableItem.playerId = player.playerId;
            tableItem.shirtNumber = player.shirtNumber;
            tableItem.name = player.name;
            tableItem.position = player.position;
            tableItem.positionId = player.positionSortOrder;
        } else {
            tableItem.sessionId = sessionInfo.sessionId;
            tableItem.name = sessionInfo.oppositionName;
            tableItem.sessionDate = sessionInfo.date;
        }

        return tableItem;
    }
}

export default class ReportingTableDataGenerator {
	static createTableData(data, dataType, matchType) {
		const builder = new DataEntryBuilder(data, dataType, matchType);
		const tableData = data.map((item) => {
			builder.setItem(item);
			return builder.buildDataEntry();
		});

		tableData.sort((a, b) => {
			if (a.playerId && b.playerId) {
				return a.positionId === b.positionId
					? a.shirtNumber - b.shirtNumber
					: a.positionId - b.positionId;
			} else if (a.sessionDate && b.sessionDate) {
				return new Date(a.sessionDate) - new Date(b.sessionDate);
			}
			return 0;
		});

		return tableData;
	}
}