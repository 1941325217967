<template>
    <div class="row col-12 col-lg-10 g-0 p-0">
        <div class="col-4" v-if="displayPlayerLabels">
            <BoxWhiskerLabel :id="'player-bw-label-' + id" :data="labelData"
                :options="labelOptions" :width="180" :bwHeight="bwHeight">
            </BoxWhiskerLabel>
        </div>
        <div class="col-7 col-lg-12 p-0">
            <BoxWhiskerChart :id="'player-bw-' + id" :data="plotData"
                :options="chartOptions" :width="bwWidth"
                :tooltipAdditionalData="matchClocks"
                :tooltip-additional-data-formatter="matchClocksFormatter" :bwHeight="bwHeight">
            </BoxWhiskerChart>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: String,
        labelData: Array,
        bwWidth: Number,
        bwHeight: Number,
        plotData: Array,
        labelOptions: Object,
        chartOptions: Object,
        matchClocks: Array,
        matchClocksFormatter: Function,
        displayPlayerLabels: Boolean
    }
};
</script>

