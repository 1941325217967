<template>
    <div id="content-wrap" style="margin-left:90px" :style="{ opacity: !$root.isLoadingData ? 1 : 0 }">
        <div>
        <PageHeader>
            <PageHeaderTitle>
                <h1>
                    League-wide Team Comparisons 
                </h1>
            </PageHeaderTitle>
        </PageHeader>
        <PageLayout>
        <div class="full-width-radio-button-container" style="width: 35vw">
            <div class="dropdown-container">
                <span>Season: </span>
                <b-dropdown v-if="seasonOptions" style="width: 8vw" variant="outline-primary" :text="seasonOptions[this.selectedSeasonIndex].text">
                    <b-dropdown-item v-for="(option, index) in seasonOptions" :key="index"
                        @click="updateSeasonSelector(index)" class="pt-2">
                        {{ option.text }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            </div>
            <div class="section-container">    
                <SectionTitle :title="'KPI Totals'">
                    <MoreInfo :id="'kpi-totals-info-modal'" :title="'KPI Totals'">
                        The KPI Totals provide league-wide team comparisons from matches this season for the total and per 90 minute values. 
                        The box plot shows the distribution of the average/maximum performance of each team, 
                        with the coloured line showing your team's value. 
                        For each metric, the team total includes data from every player in the match excluding goalkeepers. 
                        This comparison excludes all non-league games including cup games and non-competitive matches.
                    </MoreInfo>
                </SectionTitle>
                <MissingDataText v-if="!hasSeasonComparisonData" :message="'No Data For This Season'"/>
                <div v-else>
                    <div  class="toggle-btn-container">
                        <RadioButton :id="'kpi-analysis-radio-group'" :modal="displayMaxStandardKpisData" :options="avgMaxOptions"
                            :name="'kpi-options'" @update-modal="displayMaxStandardKpisData = $event" :task="updateStandardTeamComparisonPlots" />
                        <RadioButton :id="'kpi-rates-radio-group'" :modal="displayPer90Data" :options="rateOptions"
                            :name="'kpi-options'" @update-modal="displayPer90Data = $event" :task="updateStandardTeamComparisonPlots" />
                    </div>
                    <div>
                        <TeamModuleChart
                        v-for="([key, kpi]) in Object.entries(STANDARD_KPIS_ENUM)"
                        :key="key"
                        :kpi="standardKpisRanks[key]"
                        :title="`${standardKpiUnitLabels[key]}`"
                        :hoverText="kpi.hoverText"
                        :labelId="`total-${key}-label-chart`"
                        :chartContainerId="`${key}-main-container`"
                        :chartId="`total-${key}-chart`"
                        :chartClass="standardKpisPlotsClassName"
                        :hasKpiValues="hasKpiValues[key]"/>
                    </div>
                </div>
            </div> 
            <div class="section-container">     
                <SectionTitle title="MPO">
                    <MoreInfo :id="'mpo-info-modal'" :title="'MPO'">
                        The MPO section provides league-wide team comparisons from matches this season. 
                        The box plot shows the distribution of the average/maximum performance of each team, 
                        with the coloured line showing your team's value. 
                        For each metric, the team total includes data from every player in the match excluding goalkeepers. 
                        This comparison excludes all non-league games including cup games and non-competitive matches.
                    </MoreInfo>
                </SectionTitle>
                <MissingDataText v-if="!hasSeasonComparisonData" :message="'No Data For This Season'"/>
                <div v-else>
                    <div class="toggle-btn-container">
                        <RadioButton :id="'kpi-analysis-radio-group'" :modal="displayMaxMpoData" :options="avgMaxOptions"
                            :name="'kpi-options'" @update-modal="displayMaxMpoData = $event" :task="updateMPOTeamComparisonPlots" />
                    </div>
                    <div v-for="(mpos, category) in groupedMpoEnumByCategory" :key="category">
                        <h3 v-if="Object.keys(mpos).some(key => hasMpoKpiValues[key])" class="text-center py-2 w-100 mx-auto">{{ category }}</h3>
                        <TeamModuleChart
                        v-for="([key, kpi]) in Object.entries(mpos)"
                        :key="key"
                        :kpi="mpoRanks[key]"
                        :title="mpoUnitLabels[key]"
                        :hoverText="kpi.hoverText"
                        :labelId="`total-${key}-label-chart`"
                        :chartContainerId="`${key}-main-container`"
                        :chartId="`total-${key}-chart`"
                        :chartClass="mpoPlotsClassName"
                        :hasKpiValues="hasMpoKpiValues[key]"/>
                    </div>
                </div>
            </div>
            <div class="section-container">
                <SectionTitle :title="'Peak Periods'">
                    <MoreInfo :id="'peak-periods-info-modal'" :title="'Peak Periods'">
                        The Peak Periods provide league-wide team comparisons from Premier League matches for the selected season. Individual box plots 
                        show the distribution of the average performance of each team, with the coloured boxes showing your team's value. 
                        For each metric, the team average includes data from every player in the match excluding goalkeepers. This comparison excludes 
                        all non-league games such as cup and non-competitive matches.
                    </MoreInfo>
                </SectionTitle>
                <div class="full-width-radio-button-container" style="width: 35vw">
                    <RadioButton :id="'kpi-radio-group'" :modal="kpiSelected" :options="kpiOptions"
                        :name="'kpi-options'" @update-modal="kpiSelected = $event" :task="toggleKpiGroup" />
                </div>
                <MissingDataText v-if="!hasSeasonPeakDemands" :message="'No Data For This Season'"/>
                <div v-else>
                    <div v-if="peakPeriodsData">
                        <div>
                            <VerticalBoxWhisker :id="'team-peak-30'" :data="peakPeriodsData.epoch30s" :labels="peakPeriodsData.blankLabels" :options="epoch30sOptions">
                            </VerticalBoxWhisker>
                        </div>
                        <div style="margin-top: 5vh;">
                            <VerticalBoxWhisker :id="'team-peak-60'" :data="peakPeriodsData.epoch60s" :labels="peakPeriodsData.blankLabels" :options="epoch60sOptions">
                            </VerticalBoxWhisker>
                        </div>
                        <div style="margin-top: 5vh;">
                            <VerticalBoxWhisker :id="'team-peak-120'" :data="peakPeriodsData.epoch120s" :labels="peakPeriodsData.blankLabels" :options="epoch120sOptions">
                            </VerticalBoxWhisker>
                        </div>
                    </div>
                </div>
            </div> 
        </PageLayout>
        </div>
    </div>
</template>

<script>

import { plots } from "../components/Plots";
import { UserData } from "../components/UserData";
import { errorHandler } from "../components/ErrorHandler";
import GetChartContext from "../components/GetChartContext";
import TeamModuleChart from "../components/TeamModuleChart.vue";
import PageLayout from '../components/PageLayout.vue';
import PageHeader from '../components/PageHeader.vue';
import PageHeaderTitle from '../components/PageHeaderTitle.vue';
import MissingDataText from '../components/MissingDataText.vue';
import MoreInfo from '../components/MoreInfo.vue';
import RadioButton from '../components/RadioButton.vue';
import Colours from '../utils/Colours';
import VerticalBoxWhisker from "../components/VerticalBoxWhisker.vue";
import SectionTitle from "../components/SectionTitle.vue";
import { getSeasons } from "../utils/Seasons";
import UpdateSetting from "@/utils/UpdateSetting";
import {
    convertBwData,
    getMeasurementUnit,
    MeasurementTypes,
} from '../utils/MeasurementSystem';
import { STANDARD_KPIS_ENUM, MPO_ENUM } from '../utils/TeamKpis';

export default {
    components: {
        TeamModuleChart,
        PageHeader,
        PageLayout,
        MissingDataText,
        MoreInfo,
        RadioButton,
        VerticalBoxWhisker,
        SectionTitle,
        PageHeaderTitle
    },
    computed: {
        defaultOptions() {
            return {
                tooltipLabel: "Current Match",
                showTicks: false,
                showTitle: true,
                yAxisLabel: this.kpiSelected === 'TURNS' ? 'Turns (N/min)' : `Distance (${this.sdUnit.unitShort}/min)`,
                precision: 1,
            };
        },
        epoch30sOptions() {
            return {
                ...this.defaultOptions,
                title: '30s',
            };
        },
        epoch60sOptions() {
            return {
                ...this.defaultOptions,
                title: '60s',
            };
        },
        epoch120sOptions() {
            return {
                ...this.defaultOptions,
                title: '120s',
            };
        },
        groupedMpoEnumByCategory() {
            // Group MPO enum entries by their category for easier rendering
            return Object.entries(MPO_ENUM).reduce((groups, [key, value]) => {
                const { category } = value;
                // Initialize the category group if it doesn't exist
                if (!groups[category]) {
                    groups[category] = {};
                }
                // Add the current key and its details to the appropriate category
                groups[category][key] = value;
                return groups;
            }, {});
        },
    },
    data() {
        return {
            ldUnit: getMeasurementUnit(MeasurementTypes.LongDistance),
            sdUnit: getMeasurementUnit(MeasurementTypes.ShortDistance),
            data: null,
            customerId: UserData.customerId(),
            displayMaxStandardKpisData: false,
            displayMaxMpoData: false,
            maxAverageText: 'Average',
            hasSeasonComparisonData: false,
            avgMaxOptions: [
                { text: 'Average', value: false },
                { text: 'Max', value: true }
            ],
            kpiSelected: 'DISTANCE',
            kpiOptions: [
                { text: 'Distance', value: 'DISTANCE' },
                { text: 'HSR', value: 'HSR' },
                { text: 'Sprint', value: 'SPRINT' },
                { text: 'Turns', value: 'TURNS' }
            ],
            peakPeriodsData: null,
            hasTeamPeakDemandsData: null,
            selectedSeasonIndex: 0,
            seasonSelected: null,
            seasonOptions: null,
            hasSeasonPeakDemands: false,
            earliestSession: null,
            displayPer90Data: false,
            standardKpiUnitLabels: {},
            mpoUnitLabels: {},
            rateOptions: [
                { text: 'Total', value: false },
                { text: 'Per 90 Min', value: true },
            ],
            STANDARD_KPIS_ENUM,
            MPO_ENUM,
            standardKpisRanks: {},
            mpoRanks: {},
            hasKpiValues: {},
            hasMpoKpiValues: {},
            standardKpisPlotsClassName: 'standard-kpi-team-kpi-plot',
            mpoPlotsClassName: 'mpo-team-kpi-plot',
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => { });
            console.log(newPage);
        },
        async getEarliestSessionDate() {
                const response = await this.$root.webApiGet(`/teamstartdate?customerId=${this.customerId}`);
                if (response.status === 200) {
                    this.earliestSession = response.data;
                } else {
                    errorHandler.error(response, this);
                }
        },
        async getData() {
            const seasonStart = this.seasonSelected.startDate.toISOString().substring(0, 10);
            const seasonEnd = this.seasonSelected.endDate.toISOString().substring(0, 10);
            const response = await this.$root.webApiGet(`/teamcomparison?customerId=${this.customerId}&startDate=${seasonStart}&endDate=${seasonEnd}`);

            if (response.status === 200) {
                this.data = response.data;
                this.hasSeasonComparisonData = Object.keys(this.data.teamComparisonData.averageData.kpis.totalKpis).length > 0;
                // Check any graph to see if we were present and data exists for that season
                this.hasSeasonPeakDemands = this.data.teamPeakDemands.dataByEpochByKpi[this.kpiSelected] &&
                    this.data.teamPeakDemands.dataByEpochByKpi[this.kpiSelected]["_30S"].customerRank !== 0;
            } else {
                errorHandler.error(response, this);
            }
        },
        getTeamComparisonPlots(config) {
            const { enumObject, displayMaxData, displayPer90Data, className } = config;
            const results = { hasKpiValues: {}, kpiRanks: {}, unitLabels: {} };

            if (this.hasSeasonComparisonData) {
                const { maxData, averageData, maxSessions } = this.data.teamComparisonData;
                const teamData = displayMaxData ? maxData : averageData;
                const { kpis, rankings } = teamData;
                const { totalRanks, per90Ranks, totalTeams } = rankings;
                const { totalKpis, per90Kpis } = kpis;
                const { maxTotalSessions, maxPer90Sessions } = maxSessions;

                const maxAverageText = displayMaxData ? 'Max' : 'Average';
                //create ranking system
                const createTeamRank = rank => new Array(totalTeams).fill().map((_, i) => ({ color: i + 1 === rank ? Colours.SPORTLIGHT_TEAL : Colours.SECONDARY_DARK_GREY, position: i + 1 })).reverse();

                const checkPer90Value = (value, valuePer90, hasPer90) => displayPer90Data && hasPer90 ? valuePer90 : value;

                Object.entries(enumObject).forEach(([key, { unit, paddingValue, hasPer90, decimalPlaces, title }]) => {
                    const rank = checkPer90Value(totalRanks[key], per90Ranks[key], hasPer90);
                    const value = checkPer90Value(totalKpis[key], per90Kpis[key], hasPer90);

                    if (value) {
                        results.hasKpiValues[key] = value !== undefined;
                        const per90Text = displayPer90Data && hasPer90 ? 'per 90 min' : '';
                        // Format the label by combining unit and per90Text ignoring empty values
                        const label = [unit?.unitShort || '', per90Text || ''].join(' ').trim();
                        // Assign the formatted label to unitLabels wrapped in parentheses if it exists
                        const unitText = label ? `(${label})` : '';
                        results.unitLabels[key] = `${maxAverageText} ${title} ${unitText}`;

                        results.kpiRanks[key] = createTeamRank(rank);
                        const tooltipLabels = displayMaxData ? { Value: "Team Average Max", Min: "League Min Max", Average: "League Average Max", Max: "League Max Max" }
                            : { Value: "Team Average", Min: "League Min", Average: "League Average", Max: "League Max" };

                        const options = {
                            decimalPlaces: decimalPlaces || 0,
                            title: '',
                            label: ['Total', 'Rank'],
                            rank: `${rank}/${totalTeams}`,
                            padding: unit ? unit.convert(displayPer90Data && hasPer90 ? paddingValue / 10 : paddingValue) : paddingValue,
                            additionalLabel: displayMaxData ? 'Opposition: ' + checkPer90Value(maxTotalSessions[key], maxPer90Sessions[key], hasPer90) : null,
                            tooltipLabels,
                            chartType: 'team',
                            hasTitle: false,
                            hasTicks: false,
                            chartSize: 'L',
                            hasRank: true,
                        };

                        this.getChartDetails(
                            unit ? convertBwData(value, unit) : value,
                            className,
                            `total-${key}-chart`,
                            `total-${key}-label-chart`,
                            `${key}-main-container`,
                            options
                        );
                    }
                });
            }
            return results;
        },
        getChartDetails(kpi, className, chartId, labelId, chartContainer, options) {
            const chartCtx = GetChartContext(chartId, chartContainer, className);
            const labelCtx = document.getElementById(labelId).getContext("2d");
            const chartData = { kpi: [kpi], labels: options.label };
            plots.bwPlot(labelCtx, chartCtx, chartData, options);
        },
        async getTeamPeakDemandsPlots() {
            if (this.hasSeasonPeakDemands) {

                const generateDecoratedMetricStatsForKpiAndEpoch = epoch => {
                    
                    const dataEntry = this.data.teamPeakDemands.dataByEpochByKpi[this.kpiSelected][epoch];

                    return dataEntry.metricStatsInDescendingValueOrder.map((metricStat, index) => {
                        if (this.kpiSelected !== 'TURNS') {
                            metricStat = convertBwData(metricStat, this.sdUnit);
                        }
                        const isCurrentCustomer = dataEntry.customerRank === index + 1;
                        metricStat.whiskersColour = isCurrentCustomer ? Colours.SPORTLIGHT_TEAL : Colours.SECONDARY_LIGHT_GREY;
                        metricStat.boxColour = isCurrentCustomer ? Colours.TRANSLUCENT_SPORTLIGHT_TEAL : Colours.TRANSLUCENT_SECONDARY_LIGHT_GREY;
                        return metricStat;
                    });
                };

                const epochData30s = generateDecoratedMetricStatsForKpiAndEpoch("_30S");
                
                this.peakPeriodsData = {
                    epoch30s: epochData30s,
                    epoch60s: generateDecoratedMetricStatsForKpiAndEpoch("_60S"),
                    epoch120s: generateDecoratedMetricStatsForKpiAndEpoch("_120S"),
                    blankLabels: Array(epochData30s.length).fill('')
                };
            }
        },
        async getSeasonOptions(){
            this.seasonOptions = getSeasons(new Date(this.earliestSession), false);
        },
        getStandardComparison() {
            const config = {
                enumObject: STANDARD_KPIS_ENUM,
                displayMaxData: this.displayMaxStandardKpisData,
                displayPer90Data: this.displayPer90Data,
                className: this.standardKpisPlotsClassName,
            };
            const { hasKpiValues, kpiRanks, unitLabels } = this.getTeamComparisonPlots(config);
            this.hasKpiValues = hasKpiValues;
            this.standardKpisRanks = kpiRanks;
            this.standardKpiUnitLabels = unitLabels;
        },
        getMpoComparison() {
            const config = {
                enumObject: MPO_ENUM,
                displayMaxData: this.displayMaxMpoData,
                displayPer90Data: false,
                className: this.mpoPlotsClassName,
            };
            const { hasKpiValues, kpiRanks, unitLabels } = this.getTeamComparisonPlots(config);
            this.hasMpoKpiValues = hasKpiValues;
            this.mpoRanks = kpiRanks;
            this.mpoUnitLabels = unitLabels;
        },
        updateStandardTeamComparisonPlots() {
            this.resetPlots(this.standardKpisPlotsClassName);
            this.getStandardComparison();
        },
        updateMPOTeamComparisonPlots() {
            this.resetPlots(this.mpoPlotsClassName);
            this.getMpoComparison();
        },
        resetPlots(className) {
            Array.from(document.getElementsByClassName(className)).forEach(e => e.remove()); //remove existing plots
        },
        toggleKpiGroup() {
            this.getTeamPeakDemandsPlots();
        },
        async updateSeasonSelector(p) {
            this.selectedSeasonIndex = p;
            this.seasonSelected = this.seasonOptions[p];
            this.$root.executeTaskWithProgressBar(async () => {
                await this.getData();
                await this.getTeamPeakDemandsPlots();
                this.updateStandardTeamComparisonPlots();
                this.updateMPOTeamComparisonPlots();
            });
        }
    },
    async mounted() {
        this.$root.executeTaskWithProgressBar(async () => {
            console.log("Team.vue mounted");
            this.$root.newPageView("Team Page", UserData.userName());
            await this.getEarliestSessionDate();
            await this.getSeasonOptions();
            this.seasonSelected = this.seasonOptions[0];
            await this.getData();
            this.getStandardComparison();
            this.getMpoComparison();
            await this.getTeamPeakDemandsPlots();
            UpdateSetting();
        });
    },
};
</script>
<style>
.toggle-btn-container {
    width: 20vw; 
    display: flex; 
    align-items: center; 
    justify-content: space-around; 
    margin: auto;
}
.dropdown-container {
    margin: auto;
    min-width: 12vw;
    display: flex;
    height: 5vh;
    justify-content: space-between;
    align-items: center;
}
</style>