<template>
    <!-- prettier-ignore -->
    <div class="report-charts-container">
        <SectionTitle :title="'Turn Summary (Within Player)'">
            <MoreInfo :id="'turn-summary-info-modal'" :title="'Turn Summary (Within Player) Description'">
                <ul>
                <li>Turn Summary provides a visual comparison between the team’s & players’ current session and their previous ones. 
                The coloured line represents the current session, while the box and whisker plot represents the distribution of previous matches.</li>
                <li>Total: Current session compared with all previous {{playersSessionReferenceGroup}} matches with at least 85 minutes of match time {{getTotalText}}</li>
                <BoxWhiskerAdditionalInfoText currentDataText="current session" referenceDataText="team's and players'" />
                </ul>
            </MoreInfo>
        </SectionTitle>
        <div class="full-width-radio-button-container">
            <RadioButton :id="'turns-radio-group'" :modal="turnCategorySelected" :options="turnCategoryOptions"
                :label="'Select Turns Category'" :name="'turns-radio-options'" @update-modal="turnCategorySelected = $event"
                :task="selectedCategory" />
        </div>
        <MissingDataText v-if="!hasData" :message="'Turn Report Unavailable'" />
        <div v-else class="mt-4">
            <EnhancedTurnIndicator />
            <div class="row w-100 mx-auto">
                <div class="row col-lg-2 p-0" v-if="!isBelowBreakpoint">
                    <div class="col-12">
                        <TurnReportColumn
                        canvasId="team-report-label"
                        :chartOptions="teamLabelOptions"
                        :data="teamLabelData"
                        :chartFunction="plots.bwPlotLabels"
                        :style="'height: ' + teamRowHeight"
                        :refresh="refresh"
                        v-if="sessionIsAMatch"/>
                        <TurnReportColumn
                        :style="'height: ' + playerColumnHeight + 'px'"
                        canvasId="player-report-label"
                        :chartOptions="playerLabelOptions"
                        :data="playerLabelData"
                        :chartFunction="plots.bwPlotLabels"
                        :refresh="refresh" />
                    </div>
                </div>
                <div class="row col-lg-10 p-0 m-0">
                    <div class="col-12 w-100 g-0 p-0 mx-auto" v-for="classification in classifications"
                        :class="classifications.length === 4 ? 'col-lg-3' : 'col-lg-4'" :key="'team-' + classification">
                        <div class="row col-12" v-if="sessionIsAMatch">
                            <div class="col-4" v-if="isBelowBreakpoint">
                                <TurnReportColumn
                                :canvasId="classification + '-team-chart-label'"
                                :chartOptions="teamLabelOptions"
                                :data="teamLabelData"
                                :chartFunction="plots.bwPlotLabels"
                                :style="'height: ' + teamRowHeight"
                                :refresh="refresh"/>
                            </div>
                            <div class="col-8 col-lg-12">
                                <TurnReportColumn  
                                :canvasId="classification + '-team-chart'"
                                :chartOptions="teamChartOptionsByClassification[classification]"
                                :data="teamDataByClassification[classification]"
                                :chartFunction="plots.bwPlotValues"
                                :style="'height: ' + teamRowHeight"
                                :refresh="refresh"/>
                            </div>
                        </div>
                        <div class="row col-12">
                            <div class="col-4" v-if="isBelowBreakpoint">
                                <TurnReportColumn
                                :style="'height: ' + playerColumnHeight + 'px'"
                                :canvasId="classification + '-player-chart-label'"
                                :chartOptions="playerLabelOptions"
                                :data="playerLabelData"
                                :chartFunction="plots.bwPlotLabels"
                                :refresh="refresh"/>
                            </div>
                            <div class="col-8 col-lg-12">
                                <TurnReportColumn 
                                :style="'height:' + playerColumnHeight + 'px'"
                                :canvasId="classification + '-player-chart'"
                                :chartOptions="playerChartOptionsByClassification[classification]"
                                :data="playersDataByClassification[classification]"
                                :chartFunction="plots.bwPlotValues"
                                :refresh="refresh"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { errorHandler } from "@/components/ErrorHandler";
import { UserData } from "@/components/UserData";
import {getMeasurementUnit, MeasurementTypes} from "@/utils/MeasurementSystem";
import UpdateSetting from "@/utils/UpdateSetting";
import store from '@/store/session';
import { getTurnEntrySpeedClassifications } from "@/utils/TurnEntrySpeedBoundaryHelpers";
import TurnReportColumn from './TurnReportColumn.vue';
import { plots } from "@/components/Plots";
import BREAKPOINT_LG from "@/utils/Breakpoints";

export default {
    components: {
        TurnReportColumn
    },
    data() {
        return {
            data: [],
            responseData: {},
            responseStatus: null,
            turnCategorySelected: 'entrySpeedReport',
            turnCategoryOptions: [
                { text: 'Entry Speed', value: 'entrySpeedReport' },
                { text: 'Turn Angle', value: 'angleReport' },
                { text: 'Turn Peak Accelerations', value: 'accelerationReport' },
                { text: 'Turn Peak Decelerations', value: 'decelerationReport' },
            ],
            measurementUnit: getMeasurementUnit(MeasurementTypes.Speed),
            isWIP: UserData.isWIP(),
            customerId: UserData.customerId(),
            hasData: true,
            turnEntrySpeedBoundaries: UserData.getTurnEntrySpeedBoundaries(),
            classificationTitles: [],
            playerLabelOptions: {},
            playerLabelData: {},
            playerChartOptionsByClassification: {},
            playersDataByClassification: {},
            teamLabelOptions: {},
            teamLabelData: {},
            teamChartOptionsByClassification: {},
            teamDataByClassification: {},
            refresh: 0,
            plots: plots,
            // the following variables are used to size the table appropriately. This is to avoid the situation where
            // a rehab session with 1 player could have a huge bw plot, and a game with 15 players tiny bw plots for 
            // each player
            playerColumnHeight: 0, // the overall height of a column in the 'players' part of the table
            columnHeaderHeight: 60, // a guess at the height of the column header (Walking, jogging etc...)
            playerRowHeight: 50, // the space allocated for a row in the 'players' part of the table,
            isBelowBreakpoint: window.innerWidth <= BREAKPOINT_LG,
            teamRowHeight: "20vh"
            
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => { });
        },
        async refreshData() {
            // get query string here
            this.sessionId = this.$route.params.id;
            await this.$root.executeTaskWithProgressBar(async () => {
                await this.fetchData();
                this.getTurns();
            });
        },
        // takes an array e.g. [1, 2, 3], a function, e.g. i -> [i + 5], and a separator item e.g. -1  and returns a new array with the 
        // function applied to each item, separated by the separator
        // e.g. [6, -1, 7, -1, 8]
        intersperse(arr, func, separator) {
            const array = arr.filter(n => n); // remove null / undefined items from the array

            // see https://stackoverflow.com/questions/37128624/terse-way-to-intersperse-element-between-all-elements-in-javascript-array
            return array.flatMap((el, i) => i === 0 ? func(el) : [separator].concat(func(el)));
        },
        // takes a function and returns and object that maps each classification to the value returned when applying the function to it
        // e.g. {'classification1': func('classification1'), 'classification2': func('classification2')...}
        createClassificationsMap(func) {
            return this.classifications.reduce((accumulator, c) => {
                accumulator[c] = func(c);
                return accumulator;
            }, {});
        },
        async fetchData() {
            const response = await this.$root.webApiGet(
                `/turnreport?customerId=${this.customerId}&sessionId=${this.sessionId}`
            );

            if (response.status === 200) {
                this.responseStatus = response.status;
                this.responseData = response.data;
            } else {
                errorHandler.error(response, this);
            }
        },
        getTurns() {
            if (this.responseStatus === 200) {
                this.turnEntrySpeedBoundaries = this.responseData.turnEntrySpeedBoundaries;
                const teamTurns = this.responseData.teamTotals[this.turnCategorySelected];
                const playersTurns = this.responseData.playerEntries.filter(t => t.report[this.turnCategorySelected]);
                this.hasData = playersTurns.length > 0;
                if (this.hasData) {
                    
                    if (this.turnCategorySelected === 'entrySpeedReport') {
                        this.measurementUnit = getMeasurementUnit(MeasurementTypes.Speed);
                        const turnEntryDetails = getTurnEntrySpeedClassifications(this.turnEntrySpeedBoundaries, this.measurementUnit);

                        for (let i = 0; i < turnEntryDetails.length; i++) {
                            const name = turnEntryDetails[i].name;
                            this.classificationTitles[name] = name + ' (' + turnEntryDetails[i].text + ')';
                        }
                    } else if (this.turnCategorySelected === 'decelerationReport' || this.turnCategorySelected === 'accelerationReport') {
                        this.measurementUnit = getMeasurementUnit(MeasurementTypes.Acceleration);
                        this.classificationTitles['Low'] = 'Low (<' + this.measurementUnit.formatCoarseUnit(4) + ')';
                        this.classificationTitles['Medium'] = 'Medium (>' + this.measurementUnit.formatCoarseUnit(4) + ' & <' + this.measurementUnit.formatCoarseUnit(5) + ')';
                        this.classificationTitles['High'] = 'High (>' + this.measurementUnit.formatCoarseUnit(5) + ')';
                    } else {
                        const degree = "\u00B0";
                        this.classificationTitles['Low'] = `Low (<60${degree})`;
                        this.classificationTitles['Medium'] = `Medium (>60${degree} & <120${degree})`;
                        this.classificationTitles['High'] = `High (>120${degree})`;
                    }

                    this.data = playersTurns;

                    const groupedTurns = playersTurns.reduce((t, i) => {
                        t[i.player.positionGroupSortOrder] = t[i.player.positionGroupSortOrder] || [];
                        t[i.player.positionGroupSortOrder].push(i);
                        return t;
                    }, []);

                    const blankData = {
                        value: null,
                        minimum: null,
                        maximum: null,
                        standardDeviation: null,
                        average: null
                    };
                    const playerNames = this.intersperse(groupedTurns, group => group.map(t => t.player.name), '');
                    const activeTimes = this.intersperse(groupedTurns, group => group.map(t => t.activeTimeM), 0);

                    const valuesByClassification = this.createClassificationsMap(c => 
                        this.intersperse(
                            groupedTurns,
                        group => group.map(t => t.report[this.turnCategorySelected][c]),
                            blankData
                        )
                    );
                                        
                    const playerOptions = {
                        'chartType': 'player',
                        'hasTicks': false,
                        'hasTitle': !this.sessionIsAMatch,
                        'activeTime': activeTimes,
                        'chartSize': 'L',
                        sessionIsAMatch: this.sessionIsAMatch
                    };
                    
                    this.playerLabelOptions = {
                        'labels': playerNames,
                        ...playerOptions
                    };
                    
                    this.playerLabelData = {'labels': playerNames };

                    this.playerChartOptionsByClassification = this.createClassificationsMap(c => {
                        const averages = Object.values(valuesByClassification[c]).map(item => item.value);
                        const totalAverage = averages.reduce((sum, current) => sum + current, 0) / averages.length;
                        return {
                            'title': this.classificationTitles[c],
                            'padding': totalAverage < 1 ? 2 : totalAverage,
                            'decimalPlaces': 0,
                            ...playerOptions
                        };
                    });
                    
                    const teamOptions = {
                        'chartType': 'team',
                        'hasTicks': false,
                        'hasTitle': true,
                        'chartSize': 'S',
                        sessionIsAMatch: this.sessionIsAMatch
                    };

                    this.teamLabelOptions = {
                        'labels': ['TEAM TOTAL'],
                        ...teamOptions
                    };
                    this.teamLabelData = {'labels': ['TEAM TOTAL']};

                    this.teamChartOptionsByClassification = this.createClassificationsMap(c => {
                            return {
                                'title': this.classificationTitles[c],
                                'padding': 25,
                                'activeTime': 0,
                            ...teamOptions
                            };
                        }
                    );

                    const getTurnsInfo = (kpi, options) => {
                        const labels = options.labels;
                        const times = options.activeTime;
                        return {'kpi': kpi, 'labels': labels, 'activeTime': times};
                    };

                    const getPlayersTurns = t => {
                        return t.map(o => o.value === undefined ? blankData : o);
                    };

                    this.playersDataByClassification = this.createClassificationsMap(c => 
                        getTurnsInfo(getPlayersTurns(valuesByClassification[c]), this.playerChartOptionsByClassification[c])
                    );

                    if (this.sessionIsAMatch) {

                        this.teamDataByClassification = this.createClassificationsMap(
                            c => getTurnsInfo([teamTurns[c]], this.teamChartOptionsByClassification[c])
                        );

                    }

                    this.playerColumnHeight = 0;
                    if (playerNames) {
                        this.playerColumnHeight = (playerNames.length * this.playerRowHeight);
                    }
                    if (!this.sessionIsAMatch) {
                        this.playerColumnHeight += this.columnHeaderHeight;
                    }
                    
                    this.refresh +=1;
                }
            }
        },
        selectedCategory() {
            this.getTurns();
        },
        updateBreakpointStatus() {
            this.isBelowBreakpoint = window.innerWidth <= BREAKPOINT_LG;
        }
    },
    computed: {
        sessionIsAMatch() {
            return store.state.sessionIsAMatch;
        },
        playersSessionReferenceGroup() {
            return store.state.playersSessionReferenceGroup;
        },
        teamSessionReferenceGroup() {
            return store.state.teamSessionReferenceGroup;
        },
        getTotalText: function () {
            if (store.state.sessionIsAcademyMatch) {
                return `for the players and all previous ${this.teamSessionReferenceGroup} matches with at least 85 minutes of match time for the team`;
            }
            return null;
        },
        classifications: function() {
            if (this.turnCategorySelected === 'entrySpeedReport') {
                return this.turnEntrySpeedBoundaries.names;
            } else {
                return ['Low', 'Medium', 'High'];
            }
        }
    },
    async mounted() {
        console.log("TurnReport.vue mounted");
        this.$root.newPageView("Turn Report Page", UserData.userName());
        window.addEventListener("resize", this.updateBreakpointStatus);
        await this.refreshData();
        UpdateSetting();
    }
};
</script>
<style scoped>
.report-charts-container{
    margin-top: 5vh;
}

.player-report-label,
.team-report-label{
    width: 10%;
}
</style>