import { MeasurementTypes, getMeasurementUnit } from "@/utils/MeasurementSystem";

const STANDARD_KPIS_ENUM = {
	DISTANCE: {
		title: "Total Distance",
		hoverText: "Team averaged (all players) Totals this season *",
		unit: getMeasurementUnit(MeasurementTypes.LongDistance),
		paddingValue: 15000,
		hasPer90: true,
		decimalPlaces: 1
	},
	HSR: {
		title: "Total HSR",
		hoverText: "Team averaged (all players) Totals this season *",
		unit: getMeasurementUnit(MeasurementTypes.ShortDistance),
		paddingValue: 1000,
		hasPer90: true,
	},
	SPRINT_DISTANCE: {
		title: "Total Sprint Distance",
		hoverText: "Team averaged (all players) Totals this season *",
		unit: getMeasurementUnit(MeasurementTypes.ShortDistance),
		paddingValue: 200,
		hasPer90: true,
	},
	TURNS: {
		title: "Total Turns",
		hoverText: "Team averaged (all players) Totals this season *",
		unit: getMeasurementUnit(MeasurementTypes.Count),
		paddingValue: 30,
		hasPer90: true,
	},
};
const MPO_ENUM = {
	MPO_COMBINED: {
		category: "Full Match MPO",
		title: "MPO (In Play Combined)",
		hoverText: "Team averaged (all players) Totals this season *",
		paddingValue: 10
	},
	MPO_INTENSIVE: {
		category: "Full Match MPO",
		title: "MPO (In Play Intensive)",
		hoverText: "Team averaged (all players) Totals this season *",
		paddingValue: 10
	},
	MPO_EXTENSIVE: {
		category: "Full Match MPO",
		title: "MPO (In Play Extensive)",
		hoverText: "Team averaged (all players) Totals this season *",
		paddingValue: 10
	},
	IN_POSSESSION_MPO_COMBINED: {
		category: "In Possession MPO",
		title: "MPO (In Possession Combined)",
		hoverText: "Team averaged (all players) Totals this season *",
		paddingValue: 10
	},
	IN_POSSESSION_MPO_INTENSIVE: {
		category: "In Possession MPO",
		title: "MPO (In Possession Intensive)",
		hoverText: "Team averaged (all players) Totals this season *",
		paddingValue: 10
	},
	IN_POSSESSION_MPO_EXTENSIVE: {
		category: "In Possession MPO",
		title: "MPO (In Possession Extensive)",
		hoverText: "Team averaged (all players) Totals this season *",
		paddingValue: 10
	},
	OUT_POSSESSION_MPO_COMBINED: {
		category: "Out of Possession MPO",
		title: "MPO (Out of Possession Combined)",
		hoverText: "Team averaged (all players) Totals this season *",
		paddingValue: 10
	},
	OUT_POSSESSION_MPO_INTENSIVE: {
		category: "Out of Possession MPO",
		title: "MPO (Out of Possession Intensive)",
		hoverText: "Team averaged (all players) Totals this season *",
		paddingValue: 10
	},
	OUT_POSSESSION_MPO_EXTENSIVE: {
		category: "Out of Possession MPO",
		title: "MPO (Out of Possession Extensive)",
		hoverText: "Team averaged (all players) Totals this season *",
		paddingValue: 10
	},
};

export { STANDARD_KPIS_ENUM, MPO_ENUM };