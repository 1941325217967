<template>
    <div>
        <MissingDataText v-if="turns.length === 0" :message="'Turn Time Table unavailable'" />
        <div class="mt-4" v-else>
            <h1 id="table-title">Turn Time Table</h1>
            <table id="turn-time-table">
                <thead>
                    <tr>
                        <th>Player</th>
                        <th>0.3s Count</th>
                        <th>0.5s Count</th>
                        <th>1s Count</th>
                    </tr>
                </thead>
                <tbody v-for="player in turns" v-bind:key="player.player.playerId">
                    <tr>
                        <td>{{ player.player.name }}</td>
                        <td>{{ player.summary.lessThan300ms }}</td>
                        <td>{{ player.summary.lessThan500ms }}</td>
                        <td>{{ player.summary.lessThan1s }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { errorHandler } from "@/components/ErrorHandler";
import { UserData } from "@/components/UserData";

export default {
    data() {
        return {
            sessionId: 0,
            turns: [],
            customerId: UserData.customerId()
        };
    },
    methods: {
        navigateTo(newPage) {
            this.$router.push(newPage).catch(() => { });
        },
        async refreshData() {
            // get query string here
            this.sessionId = this.$route.params.id;
            await this.$root.executeTaskWithProgressBar(async () => await this.getTurns());
        },

        async getTurns() {
            const response = await this.$root.webApiGet(
                `/turns?customerId=${this.customerId}&sessionId=${this.sessionId}`
            );

            if (response.status === 200) {
                let data = response.data;
                data.sort(
                    (a, b) =>
                        a.player.positionSortOrder - b.player.positionSortOrder
                );
                this.turns = data;
            } else {
                errorHandler.error(response, this);
            }
        }
    },

    async mounted() {
        console.log("TurnTimeTable.vue mounted");
        this.$root.newPageView("Turn Time Table Page", UserData.userName());
        this.refreshData();
    }
};
</script>