<template>
    <div class="col-10 col-lg-6 w-100 mt-4"  v-if="playersData && teamData">
        <MissingDataText v-if="!playersData.hasValues && !teamData.hasValues" :message="'Chart Data Unavailable'" />
        <div v-else>
            <div v-if="teamData.hasValues && sessionIsAMatch">
                <div class="d-flex row w-100">
                    <div class="col-3">
                        <BoxWhiskerLabel :id="teamLabelId" :data="teamData.labels" :options="teamOptions" :bwHeight="bwHeight"
                            :width="labelWidth"></BoxWhiskerLabel>
                    </div>
                    <div class="col-8">
                        <BoxWhiskerChart :id="teamChartId" :data="teamData.kpi" :options="teamOptions" :width="plotWidth" :bwHeight="bwHeight">
                        </BoxWhiskerChart>
                    </div>
                </div>
            </div>
            
            <MissingDataText v-if="!playersData.hasValues" :message="'Players Chart Unavailable'" />
            <div v-else>
                <div class="d-flex row w-100">
                    <div class="col-3">
                        <BoxWhiskerLabel :id="playersLabelId" :data="playersData.labels" :options="playersOptions" :bwHeight="bwHeight" :width="labelWidth">
                        </BoxWhiskerLabel>
                    </div>
                    <div class="col-8">
                        <BoxWhiskerChart :id="playersChartId" :data="playersData.kpi" :options="playersOptions" :bwHeight="bwHeight" :width="plotWidth">
                        </BoxWhiskerChart>
                    </div>
                </div>
            </div>
    </div>
</div></template>

<script>
import MissingDataText from "../components/MissingDataText";
import BoxWhiskerChart from "../components/BoxWhiskerChart.vue";
import BoxWhiskerLabel from "../components/BoxWhiskerLabel.vue";
import store from "../store/session";
import BREAKPOINT_LG from "@/utils/Breakpoints";

export default {
    data() {
        return {
            plotWidth: window.innerWidth <= BREAKPOINT_LG ? 65 : 30,
            labelWidth: 180
        };
    },
    props: {
        teamLabelId: String,
        teamChartId: String,
        playersLabelId: String,
        playersChartId: String,
        teamOptions: Object,
        teamData: Object,
        playersData: Object,
        playersOptions: Object,
    },
    components: {
        MissingDataText,
        BoxWhiskerChart,
        BoxWhiskerLabel,
    },
    computed: {
        sessionIsAMatch() {
            return store.state.sessionIsAMatch;
        },
        bwHeight() {
            return this.playersData.kpi.length <= 2 ? 110 : 80;
        },

    },
    methods: {
        updatePlotWidth() {
            this.plotWidth = window.innerWidth <= BREAKPOINT_LG ? 65 : 30;
        }
    },
    mounted() {
        window.addEventListener("resize", this.updatePlotWidth);
    }
};
</script>